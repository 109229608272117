.navigationBar {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 160000003;
  top: 0;
  opacity: 1;
  background-color: #FFFFFF;
  -webkit-transition: top .5s ease;
  transition: top .5s ease; }

.buttonPointer:hover {
  cursor: pointer; }

.navigation__breadcrumbsWrapper {
  position: fixed;
  width: 100%;
  z-index: 160000000;
  padding: 8px 0;
  top: 72px;
  background: #FFFFFF; }
  @media (min-width: 992px) {
    .navigation__breadcrumbsWrapper {
      top: 95px; } }
  .navigation__breadcrumbsWrapper .container {
    padding-left: 0 !important; }

.navigation--borderBottom {
  border-bottom: 1px solid #DCDCDC; }

@media (max-width: 991px) {
  .navigation {
    border-bottom: 1px solid #DCDCDC !important; } }

@media (max-width: 992px) {
  .navigation__hideOnMobile {
    display: none !important; } }

@media (min-width: 992px) {
  .navigation__hideOnWeb {
    display: none !important; } }

.navigation .container {
  position: static; }

.navigation--mobile {
  height: 100vh;
  position: relative; }
  @media (min-width: 992px) {
    .navigation--mobile {
      height: auto;
      position: static; } }

.navigation__regionModal {
  position: relative; }

.navigation__regionModalWrapper {
  position: absolute;
  top: 38px;
  right: 8px;
  z-index: 1;
  width: 240px; }

.navigation__hide {
  display: none !important; }

@media (max-width: 992px) {
  .navigation__mobileHide {
    display: none !important; } }

@media (max-width: 992px) {
  .navigation__mobileFlex {
    display: flex !important; } }

.navigation__chevron {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 32px;
  height: 32px;
  position: relative; }
  .navigation__chevron::after {
    content: "";
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    background: url("../../images/navigation/icon-chevron-black.svg");
    transition: 0.5s; }
    @media (min-width: 992px) {
      .navigation__chevron::after {
        transform: rotate(-90deg); } }

.navigation__container {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .navigation__container {
      padding: 0 24px; } }
  @media (min-width: 992px) {
    .navigation__container {
      height: 95px; } }

.navigation__bar {
  height: 100%;
  display: flex;
  align-items: center; }

.navigation__barList {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 72px;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 104px); }
  @media (min-width: 992px) {
    .navigation__barList {
      flex-direction: row;
      position: static;
      bottom: auto;
      top: auto;
      left: 0;
      width: auto;
      height: 100%;
      display: flex; } }

.navigation__barListItem {
  border-bottom: 1px solid #DCDCDC; }
  @media (min-width: 768px) {
    .navigation__barListItem {
      width: 720px;
      margin: 0 auto; } }
  @media (min-width: 992px) {
    .navigation__barListItem {
      width: auto;
      margin: auto;
      height: 100%;
      margin-right: 10px;
      border-bottom: none; } }
  @media (min-width: 1200px) {
    .navigation__barListItem {
      margin-right: 32px; } }

.navigation__barListBtn {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: none;
  color: #161616;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  padding-right: 24px;
  padding-left: 24px; }
  .navigation__barListBtn::after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url("../../images/navigation/icon-chevron-black.svg");
    transition: 0.2s;
    transform: rotate(-90deg); }
    @media (max-width: 1023px) {
      .navigation__barListBtn::after {
        transform: rotate(0deg); } }
  .navigation__barListBtn--noArrow::after {
    display: none; }
  .navigation__barListBtn:hover::after {
    transform: rotate(90deg); }
  @media (min-width: 992px) {
    .navigation__barListBtn--active {
      color: #DB3645; }
      .navigation__barListBtn--active::after {
        background: url("../../images/navigation/icon-chevron-red.svg");
        transform: rotate(90deg); } }
  @media (min-width: 992px) {
    .navigation__barListBtn:hover .navigation__chevron::after {
      transform: rotate(90deg); } }
  @media (min-width: 768px) {
    .navigation__barListBtn {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .navigation__barListBtn {
      border-bottom: none;
      width: auto;
      justify-content: center;
      height: 100%;
      padding-right: 0;
      padding-left: 0; } }
  @media (max-width: 991px) {
    .navigation__barListBtn {
      font-weight: 600; } }

.navigation__actionBar {
  display: flex;
  align-items: center;
  flex-direction: row-reverse; }
  .navigation__actionBar .navigation__actionBarBtn--search {
    display: none; }
  @media (min-width: 992px) {
    .navigation__actionBar {
      flex-direction: row; }
      .navigation__actionBar .jsNavigationMobileMenuButton {
        display: none; }
      .navigation__actionBar .navigation__actionBarBtn--search {
        display: block; }
      .navigation__actionBar .navigationIcon--menu {
        display: none; }
      .navigation__actionBar .navigationIcon--close {
        display: none; } }

.navigation__actionBarBtn {
  width: 32px;
  height: 32px;
  font-size: 0;
  border: none;
  outline: none;
  background: transparent;
  padding-right: 0;
  padding-left: 0;
  margin-right: 8px; }
  .navigation__actionBarBtn--search {
    background: url("../../images/navigation/icon-search.svg") no-repeat;
    background-size: 100% 100%;
    background-position: center; }
    .navigation__actionBarBtn--search:hover {
      background: url("../../images/navigation/icon-search-red.svg") no-repeat;
      background-position: center; }
    .navigation__actionBarBtn--search-active {
      background: url("../../images/navigation/icon-search-red.svg") no-repeat;
      background-position: center; }
  .navigation__actionBarBtn--user {
    background: url("../../images/navigation/icon-person.svg"); }
    .navigation__actionBarBtn--user-active {
      background: url("../../images/navigation/icon-person-red.svg"); }

.navigation__countryBtn {
  width: 96px;
  overflow: hidden;
  transition: 0.2s;
  border: none;
  outline: none;
  margin-right: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  color: #161616;
  background: #EEEEEE;
  border-radius: 4px;
  font-size: 12px;
  position: relative; }
  .navigation__countryBtn::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-size: 100%;
    background: url("../../images/navigation/icon-globe-black.svg") no-repeat center; }
  .navigation__countryBtn::after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url("../../images/navigation/icon-chevron-black.svg") no-repeat center;
    background-size: 100%;
    transform: rotate(-90deg);
    transition: 0.2s; }
  .navigation__countryBtn__leftIcon {
    margin-right: 8px; }
  .navigation__countryBtn--active {
    color: #DB3645; }
    .navigation__countryBtn--active::before {
      background: url("../../images/navigation/icon-globe-red.svg") no-repeat center; }
    .navigation__countryBtn--active::after {
      background: url("../../images/navigation/icon-chevron-red.svg") no-repeat center;
      transform: rotate(90deg); }
  .navigation__countryBtn:hover {
    cursor: pointer; }
    .navigation__countryBtn:hover::after {
      transform: rotate(90deg); }

.navigation__countryBtnChevron {
  transition: 0.2s;
  width: 32px;
  height: 32px;
  display: block;
  background: url("../../images/navigation/icon-chevron-black.svg");
  transform: rotate(-90deg); }

.navigationContent {
  background: white;
  width: 100%;
  position: absolute;
  top: 76px;
  bottom: 0;
  height: calc(100% - 72px); }
  @media (min-width: 992px) {
    .navigationContent {
      height: auto;
      position: static;
      top: auto;
      bottom: auto;
      border-bottom: 1px solid #DCDCDC; } }
  @media (max-width: 991px) {
    .navigationContent {
      overflow: auto; } }
  .navigationContent__list {
    padding-left: 40px;
    width: 30%; }
    @media (max-width: 991px) {
      .navigationContent__list {
        padding-left: 0;
        width: 100%; } }
    .navigationContent__list--aboutsUs {
      width: 100%;
      height: 100%;
      display: flex; }
      @media (max-width: 991px) {
        .navigationContent__list--aboutsUs {
          flex-direction: column; } }
    .navigationContent__list--insights {
      width: 230px;
      flex-direction: column;
      padding-left: 0; }
      @media (max-width: 991px) {
        .navigationContent__list--insights {
          width: 100%; } }
  @media (max-width: 991px) {
    .navigationContent__listItem {
      border-bottom: 1px solid #DCDCDC;
      padding: 0 24px; }
      .navigationContent__listItem--noPadding {
        padding: 0; } }
  .navigationContent__listItem--noPadding {
    padding: 0 !important; }
  .navigationContent__listItem--aboutUs {
    width: 100%;
    padding-left: 24px;
    border-bottom: 1px solid #DCDCDC; }
    .navigationContent__listItem--aboutUs p {
      color: #000;
      padding: 8px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.28px;
      display: none; }
      @media (min-width: 992px) {
        .navigationContent__listItem--aboutUs p {
          display: block; } }
    @media (max-width: 1100px) {
      .navigationContent__listItem--aboutUs {
        padding-left: 20px;
        padding-right: 15px; } }
    @media (max-width: 768px) {
      .navigationContent__listItem--aboutUs {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (min-width: 992px) {
      .navigationContent__listItem--aboutUs {
        padding-left: 40px;
        width: 25%;
        border-bottom: none; } }
  .navigationContent__listItem--insightsLive button::after {
    transition: 0.2s; }
  .navigationContent__listItem--insightsLive button.navigationButton__content--active {
    color: #1c1417; }
    .navigationContent__listItem--insightsLive button.navigationButton__content--active::after {
      transform: rotate(90deg); }
  @media (max-width: 991px) {
    .navigationContent__listItem {
      padding: 0 15px; }
      .navigationContent__listItem--aboutUs {
        padding: 0 15px; } }
  @media (max-width: 767px) {
    .navigationContent__listItem {
      padding: 0 24px; }
      .navigationContent__listItem--aboutUs {
        padding: 0 24px; } }
  .navigationContent__mobileBackBtn {
    color: #DB3645;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: #EEEEEE; }
    @media (min-width: 992px) {
      .navigationContent__mobileBackBtn {
        display: none; } }
    .navigationContent__mobileBackBtn:hover {
      cursor: pointer; }
  .navigationContent__content {
    height: 100%;
    width: 100%; }
    @media (min-width: 992px) {
      .navigationContent__content {
        width: calc(100% - 324px); } }
    @media (max-width: 1023px) {
      .navigationContent__content {
        position: relative; } }
    .navigationContent__content--contact {
      display: flex; }
    .navigationContent__content--ourWork {
      overflow: auto; }
      @media (min-width: 992px) {
        .navigationContent__content--ourWork {
          overflow: hidden; } }
  .navigationContent__container {
    height: calc(100% - 104px);
    overflow: auto; }
    @media (min-width: 992px) {
      .navigationContent__container {
        padding: 32px 0;
        display: flex; } }
    @media (max-width: 1023px) {
      .navigationContent__container {
        padding-left: 0;
        padding-right: 0; } }
  .navigationContent__mainImage {
    display: none;
    width: 324px;
    height: auto; }
    .navigationContent__mainImage--height {
      height: 246px; }
    @media (min-width: 992px) {
      .navigationContent__mainImage {
        display: block; } }
    .navigationContent__mainImage img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .navigationContent__mainImageWrapper {
    width: 100%;
    height: 246px; }
  .navigationContent__title {
    color: #161616;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.28px;
    padding: 4px 0;
    display: block;
    text-decoration: none; }
    .navigationContent__title--large {
      font-weight: 600;
      height: 40px;
      border-bottom: 1px solid #DCDCDC;
      display: flex;
      align-items: center;
      padding: 0 24px;
      justify-content: space-between; }
      @media (min-width: 992px) {
        .navigationContent__title--large {
          height: 32px;
          border-bottom: none;
          padding: 4px 0; }
          .navigationContent__title--large span {
            display: none; } }
    .navigationContent__title--medium {
      font-weight: 400; }
    .navigationContent__title--chevron {
      display: flex;
      align-items: center; }
  .navigationContent__description {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.28px;
    padding: 6px 0; }
  .navigationContent__aboutUsList {
    display: flex;
    flex-direction: column; }
    .navigationContent__aboutUsList .navigationContent__description, .navigationContent__aboutUsList a:last-of-type {
      display: none; }
    @media (min-width: 992px) {
      .navigationContent__aboutUsList {
        flex-direction: row; }
        .navigationContent__aboutUsList .navigationContent__description, .navigationContent__aboutUsList a:last-of-type {
          display: flex; } }
  .navigationContent__column {
    padding-left: 40px; }
    .navigationContent__column--contact {
      width: 30%; }
    .navigationContent__column--noPaddingLeft {
      padding-left: 0; }
    .navigationContent__column--insightsLive {
      width: 50%; }
  .navigationContent__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 32px;
    color: #161616;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.28px;
    border: none;
    outline: none;
    background: transparent; }
    .navigationContent__button::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      width: 32px;
      height: 32px;
      background: url(../../images/navigation/icon-chevron-black.svg); }
    .navigationContent__button--active {
      color: #DB3645; }
      .navigationContent__button--active::after {
        background: url(../../images/navigation/icon-chevron-red.svg); }
  .navigationContent__aboutUsColumn {
    width: 100%; }
    @media (min-width: 992px) {
      .navigationContent__aboutUsColumn {
        width: 25%;
        padding-left: 40px; } }
  .navigationContent__chevron {
    display: block;
    width: 32px;
    height: 32px;
    background: url("../../images/navigation/icon-chevron-black.svg");
    margin-left: 4px; }

.navigationInput__buttonWrapper {
  position: relative; }

.navigationInput__webContainer {
  width: 211px;
  position: absolute;
  right: 0;
  bottom: -40px; }
  @media (max-width: 991px) {
    .navigationInput__webContainer {
      display: none; } }

.navigationInput__mobileForm {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 36px;
  right: 0;
  bottom: 0; }
  @media (min-width: 991px) {
    .navigationInput__mobileForm {
      display: none; } }

.navigationInput__mobileContainer {
  width: 100%;
  position: relative; }

.navigationInput__input {
  width: 100%;
  padding: 8px 48px 8px 16px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.24px;
  border-radius: 4px;
  border: 1px solid #DCDCDC;
  background: #fff;
  position: relative;
  height: 40px; }
  .navigationInput__input::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    right: 0; }
  .navigationInput__input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #161616; }

.navigationInput__iconWrapper {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent; }
  @media (max-width: 767px) {
    .navigationInput__iconWrapper {
      right: 36px; } }

.navigationIcon {
  display: block; }
  .navigationIcon__button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none; }
    .navigationIcon__button span {
      background-size: 100% 100%; }
  .navigationIcon--inlineBlock {
    display: inline-block; }
  .navigationIcon--lg {
    width: 32px;
    height: 32px; }
  .navigationIcon--md {
    width: 24px;
    height: 24px; }
  .navigationIcon--sm {
    width: 16px;
    height: 16px; }
  .navigationIcon--searchRed {
    background: url("../../images/navigation/icon-search-red.svg"); }
  .navigationIcon--searchBlack {
    background: url("../../images/navigation/icon-search-black.svg"); }
  .navigationIcon--globeBlack {
    background: url("../../images/navigation/icon-globe-black.svg"); }
  .navigationIcon--globeRed {
    background: url("../../images/navigation/icon-globe-red.svg"); }
  .navigationIcon--chevronBlack {
    background: url("../../images/navigation/icon-chevron-black.svg"); }
  .navigationIcon--chevronRed {
    background: url("../../images/navigation/icon-chevron-red.svg"); }
  .navigationIcon--menu {
    background: url("../../images/navigation/icon-menu.svg"); }
  .navigationIcon--close {
    background: url("../../images/navigation/icon-close.svg"); }
  .navigationIcon--rotate90 {
    transform: rotate(90deg); }
  .navigationIcon--rotate180 {
    transform: rotate(180deg); }
  .navigationIcon--rotate270 {
    transform: rotate(270deg); }

.navIconHover--searchRed:hover {
  background: url("../../images/navigation/icon-search-red.svg"); }

.navIconHover--searchBlack:hover {
  background: url("../../images/navigation/icon-search-black.svg"); }

.navigationContact__container {
  width: 70%;
  background: #FFFFFF; }
  @media (max-width: 991px) {
    .navigationContact__container {
      padding-left: 0;
      position: absolute;
      left: 0;
      width: 100%; } }

.navigationContact__title {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 11px;
  letter-spacing: 0.28px; }

.navigationContact__row {
  display: flex;
  margin-bottom: 16px;
  gap: 26px; }
  .navigationContact__row--textarea {
    margin-bottom: 8px; }
  .navigationContact__row--checkbox {
    margin-bottom: 0; }

.navigationContact__inputWrapper {
  width: 50%; }
  .navigationContact__inputWrapper span {
    display: none; }
  .navigationContact__inputWrapper--textarea {
    width: 100%; }

.navigationContact__label {
  display: block;
  color: #1c1417;
  opacity: 0.4;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  padding-bottom: 8px; }
  .navigationContact__label--consent {
    font-size: 12px;
    line-height: 16px; }
    .navigationContact__label--consent span {
      font-size: 12px;
      line-height: 16px; }
    .navigationContact__label--consent a {
      text-decoration: underline;
      color: #1c1417; }

.navigationContact__input {
  width: 100%;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC; }
  .navigationContact__input--textarea {
    height: 64px;
    resize: none; }

.navigationButton:hover {
  cursor: pointer; }

.navigationButton__mobileBack {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #DB3645;
  height: 32px;
  font-size: 12px;
  letter-spacing: 0.24px;
  border: none;
  padding-left: 8px;
  width: 100%;
  position: relative;
  font-weight: 600;
  outline: none; }
  .navigationButton__mobileBack::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url(../../images/navigation/icon-chevron-red.svg);
    transform: rotate(180deg); }
  @media (max-width: 767px) {
    .navigationButton__mobileBack {
      padding-left: 12px; } }

.navigationButton__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 32px;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.28px;
  border: none;
  outline: none;
  background: transparent;
  padding-left: 0;
  width: 100%;
  text-decoration: none !important;
  margin-bottom: 6px; }
  .navigationButton__content--hover:hover {
    color: #DB3645; }
  .navigationButton__content--bold {
    font-weight: 600; }
  .navigationButton__content--hover:hover {
    color: #DB3645 !important;
    cursor: pointer; }
  @media (max-width: 991px) {
    .navigationButton__content {
      height: 40px;
      margin-bottom: 0;
      font-weight: 600; } }
  .navigationButton__content--active {
    color: #DB3645; }
    .navigationButton__content--active::after {
      background: url(../../images/navigation/icon-chevron-red.svg); }
    @media (max-width: 991px) {
      .navigationButton__content--active {
        color: #1c1417; } }
  .navigationButton__content--title {
    padding-left: 40px; }
  .navigationButton__content:visited {
    text-decoration: none; }
  .navigationButton__content::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    background: url(../../images/navigation/icon-chevron-black.svg) no-repeat; }
  .navigationButton__content--noArrow::after {
    background: transparent !important; }
  .navigationButton__content--ourWork::after {
    background: url(../../images/navigation/icon-chevron-black.svg) no-repeat !important; }
  @media (min-width: 992px) {
    .navigationButton__content--ourWork::after {
      background: transparent !important; } }
  .navigationButton__content--downArrow::after {
    transform: rotate(90deg); }
  .navigationButton__content--hover:hover {
    color: #DB3645; }

.navigationButton__contentText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 32px); }

.navigationButton--md {
  border-radius: 4px;
  height: 49px;
  padding: 0 24px; }

.navigationButton--sm {
  padding: 8px 12px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 14px; }

.navigationButton--outline {
  color: #DB3645;
  border: 1px solid #DB3645;
  background: #FFFFFF;
  position: relative;
  text-transform: uppercase;
  text-decoration: none; }
  .navigationButton--outline::after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url(../../images/navigation/icon-chevron-red.svg); }
  .navigationButton--outline:visited {
    text-decoration: none; }

.navigationButton--fill {
  border: none;
  background: #DB3645;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  text-decoration: none; }
  .navigationButton--fill:disabled {
    opacity: 0.5; }
  .navigationButton--fill:visited {
    text-decoration: none; }

.navigationButton--customizers {
  margin-top: 12px; }
  @media (min-width: 992px) {
    .navigationButton--customizers {
      margin-top: 0; } }

.navAboutUs__title {
  font-weight: 600;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .navAboutUs__title::after {
      display: none; } }

.navAboutUs__readMore {
  width: 109px;
  display: none; }
  .navAboutUs__readMore:hover {
    color: #DB3645; }
    .navAboutUs__readMore:hover::after {
      background: url(../../images/navigation/icon-chevron-red.svg) no-repeat; }
  @media (min-width: 992px) {
    .navAboutUs__readMore {
      display: flex; } }

.navAboutUs__description {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.28px; }

@media (max-width: 992px) {
  .activeButtonDisabledMobile {
    color: #161616; }
    .activeButtonDisabledMobile::after {
      background: url(../../images/navigation/icon-chevron-black.svg); } }

.navList__list::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

.navList__list::-webkit-scrollbar-track {
  background-color: #dcdedb;
  border-radius: 50px; }

.navList__list::-webkit-scrollbar-thumb {
  background-color: #4F4A47;
  border-radius: 50px; }

@media (min-width: 992px) {
  .navList__list {
    padding-left: 40px;
    width: 33%; } }

.navList__list--listSecondLevel {
  height: calc(100% - 32px);
  position: absolute;
  width: 33%;
  top: 38px;
  left: 33%;
  overflow-x: hidden; }

.navList__list--insigths {
  position: absolute;
  width: 33%;
  top: 0;
  left: 33%;
  overflow-x: hidden;
  height: auto; }
  @media (max-width: 922px) {
    .navList__list--insigths {
      position: static;
      width: 100%; } }

.navList__list--listThirdLevel {
  height: calc(100% - 32px);
  position: absolute;
  width: 33%;
  top: 38px;
  left: 66%;
  overflow-x: hidden; }

.navList__list--ses {
  padding-top: 38px; }
  @media (max-width: 1023px) {
    .navList__list--ses {
      padding-top: 0; } }

.navList__list--ourWorkWrapper {
  width: 66%;
  overflow-y: auto;
  max-height: 50vh; }
  @media (max-width: 1023px) {
    .navList__list--ourWorkWrapper {
      max-height: none;
      width: 100%; } }

.navList__list--ourWork {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .navList__list--ourWork {
      height: auto !important; } }
  .navList__list--ourWork li {
    width: 50% !important; }
  @media (max-width: 1023px) {
    .navList__list--ourWork {
      width: 100%; }
      .navList__list--ourWork li {
        width: 100% !important; } }

.navMobileSearch {
  z-index: 170000003;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 104px;
  display: flex;
  align-items: center; }
  @media (min-width: 992px) {
    .navMobileSearch {
      display: none !important; } }
  @media (max-width: 767px) {
    .navMobileSearch .container {
      padding: 0 24px; } }

.navProductsButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px; }
  @media (min-width: 992px) {
    .navProductsButtons {
      gap: 10px;
      align-items: flex-start; } }

.navRegionModal__container {
  position: absolute;
  width: 240px;
  right: 7px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  padding: 12px;
  z-index: 1;
  margin-top: -3px;
  border-radius: 4px; }

.navRegionModal__topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 6px; }

.navRegionModal__title {
  font-size: 12px; }

.navRegionModal__closeButton {
  font-size: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  background: transparent; }
  .navRegionModal__closeButton .navigationIcon--close {
    display: block !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 100%; }

.navTopBarButtons {
  display: flex;
  align-items: center; }
  @media (max-width: 922px) {
    .navTopBarButtons {
      display: none;
      flex-direction: row-reverse; } }
  .navTopBarButtons__show {
    display: flex !important; }

.navContactCheckbox {
  margin-bottom: 8px; }
  .navContactCheckbox .form-group label {
    position: relative;
    cursor: pointer;
    margin-left: -10px; }
  .navContactCheckbox .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    height: 13px;
    width: 13px;
    border: 1px solid #DB3645;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer; }
  .navContactCheckbox .form-group input:checked + label:before {
    background: #DB3645; }
  .navContactCheckbox .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 4px;
    height: 10px;
    border: solid #FFFFFF;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg); }

.navCheckboxText {
  color: #C7CBC4;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.24px; }
  .navCheckboxText--link {
    text-decoration: underline;
    text-decoration-color: #C7CBC4; }

.nav__scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

.nav__scrollbar::-webkit-scrollbar-track {
  background-color: #C7CBC4;
  border-radius: 50px; }

.nav__scrollbar::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 50px; }

.navModalRegion__listItem--active {
  color: #DB3645; }

.navModalRegion__listItem:hover {
  cursor: pointer !important;
  color: #DB3645; }

.navModalRegion__listItem label:hover {
  cursor: pointer !important;
  color: #DB3645; }

.navModal__container {
  position: absolute;
  width: 240px;
  right: 7px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  padding: 12px;
  z-index: 1;
  margin-top: -3px;
  border-radius: 4px;
  top: 36px; }

.navModal__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 6px; }

.navModal__title {
  font-size: 12px; }

.navModal__listItem {
  font-size: 12px;
  height: 30px;
  display: flex;
  align-items: center; }
  .navModal__listItem a {
    text-decoration: none;
    color: #1c1417; }
    .navModal__listItem a:hover {
      color: #DB3645; }
